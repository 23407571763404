import clsx from 'clsx';
import { FieldProps, getIn } from 'formik';
import React, { HTMLAttributes } from 'react';

import InfoText from 'lib/components/InfoText';
import FloatingLabel from 'lib/components/forms/TextField/FloatingLabel';
import FieldErrorIcon from 'lib/icons/field-error.svg';
import FieldSuccessIcon from 'lib/icons/field-success.svg';

type TextFieldProps = FieldProps & Omit<BaseTextFieldProps, 'errors' | 'hasError'>;

type InputState = 'error' | 'success' | 'default';

function getInputState(error: boolean, success: boolean) {
  if (error) {
    return 'error';
  }

  if (success) {
    return 'success';
  }
}

const icons: Partial<{ [state in InputState]: JSX.Element }> = {
  error: <FieldErrorIcon />,
  success: <FieldSuccessIcon />,
};

function TextField(props: TextFieldProps) {
  const { field, form, ...baseInputProps } = props;

  const errors = getIn(form.errors, field.name);
  const hasError = errors && getIn(form.touched, field.name);
  const errorArray = Array.isArray(errors) ? errors : [errors];

  return (
    <BaseTextField
      {...field}
      {...baseInputProps}
      errors={errorArray}
      hasError={hasError}
      disabled={props.disabled || form.isSubmitting}
    />
  );
}

type BaseTextFieldProps = React.HTMLProps<HTMLInputElement> & {
  label?: string;
  labelStyle?: 'floating' | 'regular' | 'square';
  type?: 'text' | 'password' | 'number';
  variant?: 'xs' | 'default';
  disabled?: boolean;
  ariaLabel?: string;
  success?: boolean;
  errors: any[];
  hasError: boolean;
  infoText?: string[];
  autocomplete?: string;
  inputMode?: HTMLAttributes<HTMLInputElement>['inputMode'];
  leftAddon?: React.ReactNode;
  rightAddon?: React.ReactNode;
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
};

export function BaseTextField({
  type = 'text',
  labelStyle = 'regular',
  variant = 'default',
  ...props
}: BaseTextFieldProps) {
  const {
    hasError,
    errors,
    success,
    label,
    leftContent,
    leftAddon,
    disabled,
    rightContent,
    rightAddon,
    infoText,
    className,
    href,
    ...field
  } = props;

  const state = getInputState(hasError, success ?? false);

  return (
    <div className="input-group-container overflow w-full">
      {label && labelStyle === 'regular' && (
        <label
          htmlFor={field.name!}
          className={clsx('mb-1 block', {
            'text-sm font-normal ': variant === 'default',
            'text-xs': variant === 'xs',
          })}
        >
          {label}
        </label>
      )}
      <div className="flex">
        {leftContent}
        {leftAddon && (
          <div
            className={clsx(
              'inline-flex items-center justify-center border border-r-0 border-gray-200 bg-gray-100 px-3.5',
              {
                'text-base font-light': variant === 'default',
                'text-xs': variant === 'xs',
                'text-gray-80': !state,
                'border-system-error bg-red-100 text-system-error': state === 'error',
                'border-green-200 bg-green-100 text-green-900': state === 'success',
                'text-gray-80 border-gray-200 bg-gray-100': disabled,
                'rounded-bl-base rounded-tl-base': !leftContent,
              },
            )}
          >
            {leftAddon}
          </div>
        )}
        <div className="input-container relative w-full min-w-[40px]">
          {label && labelStyle === 'floating' && (
            <FloatingLabel name={field.name!} value={field.value as string}>
              {label}
            </FloatingLabel>
          )}
          {label && labelStyle === 'square' && (
            <FloatingLabel name={field.name!} value={field.value as string} style="square">
              {label}
            </FloatingLabel>
          )}
          <input
            id={field.name}
            disabled={disabled}
            type={type}
            className={clsx(
              className,
              'w-full rounded-base border font-poppins text-base font-light text-black',
              'disabled:text-gray-80 disabled:border-gray-200 disabled:bg-gray-100',
              'transition-colors duration-200',
              {
                '!important border-system-error pr-9 text-system-error': state === 'error',
                'border-system-success pr-9': state === 'success',
                'border-gray-200': !state,
                'px-3 py-[7px]': labelStyle !== 'floating' && variant === 'default',
                'px-2 py-1': labelStyle !== 'floating' && variant === 'xs',
                'px-[10px] py-[6px] pt-5': label && labelStyle === 'floating',
                'mb-3 rounded-sm border border-neutral-900 px-[10px] py-[6px] pt-5 !shadow-[6px_6px_rgba(227,214,190,0.9)]':
                  label && labelStyle === 'square',
                'rounded-bl-none rounded-tl-none': !!leftAddon || leftContent,
                'rounded-br-none rounded-tr-none !text-sm': !!rightAddon || rightContent,
              },
            )}
            {...field}
          />
          {(hasError || success) && (
            <div
              aria-hidden
              className="absolute right-0 top-0 flex h-full w-9 items-center justify-center"
            >
              {state && icons[state]}
            </div>
          )}
        </div>
        {rightAddon && (
          <div
            className={clsx(
              'inline-flex items-center justify-center border border-l-0 border-gray-200  bg-gray-100 px-3.5',
              {
                'text-base font-light': variant === 'default',
                'text-xs': variant === 'xs',
                'text-gray-80': !state,
                'border-system-error bg-red-100 text-system-error': state === 'error',
                'border-green-200 bg-green-100 text-green-900': state === 'success',
                'text-gray-80 border-gray-200 bg-gray-100': disabled,
                'rounded-br-base rounded-tr-base text-sm': !rightContent,
              },
            )}
          >
            {rightAddon}
          </div>
        )}
        {rightContent}
      </div>
      {infoText?.map((text, i) => (
        <div className="my-1" key={i}>
          <p className="mt-1.5 font-poppins text-xs text-secondary-500">{text}</p>
        </div>
      ))}
      {hasError &&
        errors.map(
          (error, i) =>
            error && (
              <div key={i} className="my-1">
                <InfoText variant="error">{error}</InfoText>
              </div>
            ),
        )}
    </div>
  );
}

export default TextField;
